.settings {
  margin: 15px 20px 10px 20px;
}

.two_columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.buttons {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.file_uploads {
  margin: 5px;
}