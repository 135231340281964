.school-webiste-pages {
  display: flex;
}

.inner-sidebar {
  width: 250px;
  padding: 20px;
  border-right: 1px solid #ddd;
  position: fixed;
  height: 100%;
  overflow-y: auto;
}

.inner-sidebar ul {
  list-style: none;
}

.inner-sidebar li {
  margin-bottom: 5px;
}

.inner-sidebar a {
  text-decoration: none;
  color: var(--text-color);
  display: block;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.inner-sidebar a:hover,
.inner-sidebar .active-item {
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
  min-height: 100vh;
}