.sidebar {
  height: 100vh;
  width: var( --sidebar-width);
  background: var(--primary-color);
  transition: all 0.5s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar .logo-details {
  position: relative;
  left: 20px;
  height: 80px;
  display: flex;
  align-items: center;
}

.sidebar .logo-details img {
  width: 30px;
  margin: 0 4px 0 4px;
}

.sidebar .logo-details .logo_name {
  color: var(--secondary-text-color);
  font-size: 34px;
  font-weight: 500;
}

.sidebar .nav-links .mui {
  position: relative;
  top: 3px;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  height: 50px;
}

.sidebar .nav-links li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .log-out {
  color: var(--secondary-text-color);
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .log-out .links_name {
  transition: all 0.4s ease;
}

.sidebar .nav-links li .log-out .links_name:hover {
  font-size: 17px;
}

.sidebar .nav-links li a.active {
  background: var(--primary-color-light);
}

.sidebar .nav-links li a:hover {
  background: var(--primary-color-light);
}

.sidebar .nav-links li .mui {
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: var(--secondary-text-color);
}

.sidebar .nav-links li a .links_name {
  color: var(--secondary-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
}

.sidebar .nav-links .log_out {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 1240px) {
  .sidebar {
    width: 60px;
    overflow-x: hidden;
  }

  .sidebar.active {
    width: 220px;
  }

  .sidebar .logo_name {
    display: none;
  }

  .log-out .links_name {
    display: none;
  }
}

@media (max-width: 768px) {
  .sidebar {
    overflow-x: hidden;
  }
}
