.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--toolbar-height);
  background: var(--secondary-background-color);
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: all 0.5s ease;
}

.toolbar .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

nav .sidebar-button .mui-icon {
  font-size: 35px;
  margin-right: 10px;
  cursor: pointer;
}

nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}

nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: var(--background-color);
  border: 2px solid #efeef1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}

nav .search-box .search-icon {
  position: absolute;
  height: 40px;
  width: 40px;
  background: var(--primary-color);
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: var(--secondary-text-color);
  font-size: 22px;
  transition: all 0.4 ease;
}

nav .search-box .mui-icon {
  position: relative;
  top: 3px;
}

nav .profile-details {
  display: flex;
  align-items: center;
  background: var(--background-color);
  border: 2px solid #efeef1;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 2px;
}

nav .profile-details .arrow-down {
  cursor: pointer;
}

nav .profile-details img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}

nav .profile-details .admin_name {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
  margin: 0 10px;
  white-space: nowrap; /* Prevent the text from wrapping to a new line */
  overflow: hidden;    /* Hide the overflowed text */
  text-overflow: ellipsis; /* Show ellipsis (...) when the text is too long */
  max-width: 120px; /* Set a maximum width for the name (adjust this based on your layout) */
}

nav .profile-details .mui-icon {
  position: relative;
  top: 2px;
  color: var(--text-color);
}

.profile-details-loading-skeleton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-popover-content {
  padding: 8px;
}

.nav-popover-content p {
  cursor: pointer;
  transition: font-size 0.3s ease;
}

.nav-popover-content p:hover {
  font-size: 15px;
}

@media (max-width: 1150px) {
  nav {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active ~ nav {
    width: calc(100% - 220px);
    left: 220px;
  }

  .sidebarBtn {
    display: none;
  }
}

@media (max-width: 768px) {
  .search-box {
    display: none;
  }
}