.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: var(--background-color);
}

.not-found-title {
  font-size: 6rem;
  font-weight: bold;
  color: #343a40;
}

.not-found-text {
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.home-link {
  font-size: 1rem;
  color: var(--primary-color);
  text-decoration: none;
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.home-link:hover {
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
}
