.layout {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}

.content-body {
  flex-grow: 1;
  width: calc(100% - var(--sidebar-width));
}

.content-body .main-content {
  height: calc(100vh - var(--toolbar-height) - 30px);
  overflow-y: auto;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}