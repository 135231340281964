.dashboard-container {
    margin: 10px 20px;
}

.dashboard-box {
    padding: 20px;
    text-align: center;
}

.material-react-table {
    padding: 20px;
}

.upcoming-events {
    padding: 20px;
}