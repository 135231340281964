/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  background: #f5f5f5;
}

a {
  text-decoration: none;
}

:root {
  /* ===== Colors ===== */
  --primary-color: #1c74d4;
  --primary-color-light: #40a2e3;
  --primary-color-dark: color-mix(in srgb, var(--primary-color) 70%, black);
  --text-color: #61677a;
  --secondary-text-color: #fff;
  --background-color: #f5f5f5;
  --secondary-background-color: #fff;
  --green-color: #76c278;
  --orange-color: #fc5424;

  /* ===== widths ===== */
  --sidebar-width: 260px;

  /* ===== heights ===== */
  --toolbar-height: 80px;

  /* ===== Font Weights ===== */
  --font-weight: 600;

  /* ====== Box Shadows ===== */
  --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* ====== Border ===== */
  --thin-border: 1px solid var(--text-color);

  /* ====== border radius =====*/
  --border-radius: 4px;
}

/* === Custom Scroll Bar CSS === */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
  border: 1px solid #c4c4c4;
}

.details-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
