.addButton {
  margin-top: 16px;
}

.deleteIcon {
  margin-left: 8px;
}

.textField {
  margin-right: 8px;
}

.buttonContainer {
  margin-top: 20px;
}
