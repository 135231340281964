.container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(135deg, #e3f2fd 30%, #bbdefb);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-info {
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
  width: 100%;
  position: absolute;
  top: 0;
  padding: 20px 45px;
  text-align: center;
}

.company-info a {
  text-decoration: none;
  color: var(--secondary-text-color);
  font-size: 22px;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  background-color: var(--secondary-background-color);
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

@media (max-width: 768px) {
  .login-form-container {
    padding: 20px;
  }
}

form {
  width: 100%; /* Fix IE11 issue. */
  margin-top: 1em;
}

form .MuiFormControl-root {
  margin-bottom: 1em;
}

.error-container {
  min-height: 20px; /* Adjust height as needed */
  margin-bottom: 1em;
}

.button {
  margin: 1.5em 0 1em;
  background-color: var(--primary-color);
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #1565c0;
}

.error {
  color: red;
  text-align: center;
}

.copyright {
  margin-top: 2em;
  text-align: center;
}

@media (max-width: 1240px) {
  .company-info {
    display: none;
  }

  .login-form-container {
    margin: 0;
  }
}
